import IndexLayout from '@lp-root/src/components/org.layout/layout.component';
import { SkuCardInfo, TelehealthTreatment } from '@lp-root/src/modules/subscribe/payment-options';
import SubscribePageTemplate from '@lp-root/src/modules/subscribe/subscribe-page-template';
import { PageProps } from '@lp-src/utils/local-types';
import { Col, Row } from '@web/atomic';
import { graphql } from 'gatsby';
import * as React from 'react';

interface IPaymentInfo {
  url: string;
  info: SkuCardInfo;
}

/** @deprecated 6Qxlq3Py43BbkD5G telehealth was deprecated in june 2023 - https://www.notion.so/vigilantesdosono/remo-o-teleconsultas-pt1-23098acaf8de4bd085668cbbd92bf249?pvs=4 */
const TelehealthPlanPage: React.FunctionComponent<PageProps> = (props) => {
  /**
   * google-sheets with price decision
   * https://docs.google.com/spreadsheets/d/12ZmGFCh1HEppZprJCx5VphlmRaXFlDYKYOTDaR9ThCI/edit#gid=1108020185
   * there is more urls from mercado pago, but we are not using it yet:
   * https://www.notion.so/vigilantesdosono/link-consultas-mercado-pago-691453e64d5146feb163641ae62c8235
   */
  const paymentInfoList: IPaymentInfo[] = [
    {
      info: {
        priceOld: '119,00',
        price: '95,20',
        numberOfTelehealthAppointments: 1,
      },
      url: 'https://mpago.la/26Do6vd',
    },
    {
      info: {
        priceOld: '476,00',
        price: '343,67',
        numberOfTelehealthAppointments: 4,
      },
      url: 'https://mpago.la/18Bhyuv',
    },

    {
      info: {
        priceOld: '1428,00',
        price: '883,97',
        numberOfTelehealthAppointments: 12,
      },
      url: 'https://mpago.la/1VQCaKY',
    },
  ].map((sku) => ({ ...sku, price: sku.info.price }));

  return (
    <>
      <IndexLayout {...props}>
        <SubscribePageTemplate title={'Escolha o seu plano'} location={props.location} siteUrl={props.data.site.siteMetadata.siteUrl}>
          <Row center="xs">
            {paymentInfoList.map((sku, i) => {
              return (
                <Col xs={12} sm={10} md={4} mb key={i}>
                  <TelehealthTreatment hideBenefits={true} to={sku.url} skuInfo={sku.info} userHasDigitalProgram={false} periodString="" />
                </Col>
              );
            })}
          </Row>
        </SubscribePageTemplate>
      </IndexLayout>
    </>
  );
};

export default TelehealthPlanPage;

export const query = graphql`
  query AssinaturaQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
